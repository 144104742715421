<template>
  <div class="my-8 callHistory">
    <v-row>
      <v-col>
        <h2 class="main-color">{{ $t("Calls' History") }}</h2>
      </v-col>
      <v-col class="text-center">
        <label class="search" for="search">
          <input
            id="inpt_search"
            class="input_search"
            v-model="search"
            @keyup.enter="getAllHistory"
            @focus="inputFocus"
            @blur="inputBlur"
            type="text"
          />
          <v-icon
            @click="getAllHistory"
            :title="$t('Search')"
            class="search-icon"
            >search</v-icon
          >
        </label>
      </v-col>
      <v-col :class="currentAppLocale == 'en' ? 'text-right' : 'text-left'">
        <v-menu
          v-model="filterMenu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              :title="$t('Search')"
              large
              color="#7297ff"
              @click="initializeDatePicker"
              >mdi-filter</v-icon
            >
          </template>

          <v-card class="py-4">
            <v-list>
              <v-list-item class="select">
                <v-select
                  v-model="filterData.classes"
                  multiple
                  :items="classes"
                  item-text="name"
                  item-value="id"
                  :placeholder="$t('Classes')"
                  solo
                ></v-select>
              </v-list-item>

              <v-list-item>
                <v-text-field
                  :label="$i18n.t('Date From')"
                  id="from"
                  solo
                  v-model="filterData.from"
                ></v-text-field>
              </v-list-item>
              <v-list-item>
                <v-text-field
                  :label="$i18n.t('Date To')"
                  solo
                  id="to"
                  v-model="filterData.to"
                ></v-text-field>
              </v-list-item>
            </v-list>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="primary" text @click="filterMenu = false">{{
                $t("Close")
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
    <v-data-table
      :options.sync="options"
      :loading="loading"
      loading-text="Loading... Please wait"
      :headers="headers"
      :items="items"
      item-key="id"
      :show-select="false"
      class="elevation-1 level1 templates text-center"
      text="left"
      hide-default-footer
    >
    </v-data-table>
    <div class="text-center my-2">
      <v-pagination
        v-if="length > 0"
        v-model="page"
        :length="length"
        :total-visible="totalVisible"
        circle
      ></v-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "callHistory",
  data() {
    return {
      search: "",
      filterMenu: false,
      filterData: {
        classes: [],
        from: "",
        to: "",
      },
      classes: [],
      loading: false,
      options: {
        itemsPerPage: 15,
      },

      circle: true,
      page: 1,
      length: 1,
      totalVisible: 5,
      headers: [
        {
          text: this.$i18n.t("Student Name"),
          align: "start",
          sortable: false,
          value: "student_name",
        },
        {
          text: this.$i18n.t("Grade and Class"),
          value: "class_name",
          sortable: false,
        },
        { text: this.$i18n.t("Date"), value: "date", sortable: false },
        {
          text: this.$i18n.t("Call Time"),
          value: "call_time",
          sortable: false,
        },
        {
          text: this.$i18n.t("Called By"),
          value: "called_by",
          sortable: false,
        },
        {
          text: this.$i18n.t("Checkout Time"),
          value: "checkout_time",
          sortable: false,
        },
      ],
      items: [],
    };
  },
  watch: {
    page: function () {
      this.getAllHistory();
    },
    options: {
      handler() {
        if (
          this.options.sortBy.length > 0 &&
          this.options.sortDesc.length > 0
        ) {
          this.getAllHistory();
        }
      },
      deep: true,
    },
    filterData: {
      handler() {
        this.getAllHistory();
      },
      deep: true,
    },
  },
  methods: {
    initializeDatePicker() {
      let _this = this;

      setTimeout(() => {
        $("#from").calendarsPicker({
          maxDate: 0,
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.filterData.from = dateH;
            } else {
              _this.filterData.from = "";
            }
          },
        });

        $("#to").calendarsPicker({
          maxDate: 0,
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.filterData.to = dateH;
            } else {
              _this.filterData.to = "";
            }
          },
        });
      }, 1000);
    },
    getGradesClasses() {
      this.$http
        .get(this.getApiUrl + "/discussionboard/getClasses", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.classes = response.data.data;
        });
    },

    getAllHistory() {
      this.$http
        .get(
          this.getApiUrl +
            "/nedaa/callHistory?page=" +
            this.page +
            "&class=" +
            this.filterData.classes +
            "&from=" +
            this.filterData.from +
            "&to=" +
            this.filterData.to +
            "&search=" +
            this.search,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.items = response.data.data.data;
          this.page = response.data.data.current_page;
          this.options.itemsPerPage = response.data.data.per_page;
          this.length = response.data.data.last_page;
        });
    },
    openAssingDialog(id, parents_ids) {
      this.dialog = true;
      this.selectedDriverId = id;
      this.selectedParentsIds = parents_ids;
    },
    closeDialog(afterSave) {
      this.dialog = false;
      this.selectedDriverId = "";
      if (afterSave) {
        this.getAllHistory();
      }
    },
  },
  mounted() {
    this.getAllHistory();
    this.getGradesClasses();
  },
};
</script>

<style></style>
